






































































import Vue from 'vue';
import vSelect from 'vue-select';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-tw';
const Ripple = require('vue-ripple-directive');
import moment from 'moment';
import axios from 'axios';

export default Vue.extend({
  components: {
    DatePicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    const range = [moment().subtract(1, 'days').valueOf(), moment().valueOf()];
    return {
      display: false,
      alarmLevels: [
        { id: 1, txt: '資訊' },
        { id: 2, txt: '警告' },
        { id: 3, txt: '嚴重' },
      ],
      columns: [
        {
          key: 'time',
          label: '時間',
          sortable: true,
        },
        {
          key: 'level',
          label: '等級',
          sortable: true,
          formatter: (value: number) => {
            switch (value) {
              case 1:
                return '資訊';
              case 2:
                return '警告';
              case 3:
                return '嚴重';
              default:
                return '未知';
            }
          },
        },
        {
          key: 'subSrc',
          label: '來源',
          sortable: true,
        },
        {
          key: 'desc',
          label: '詳細資訊',
          sortable: true,
        },
      ],
      rows: [],
      form: {
        range,
        alarmLevel: 1,
      },
    };
  },
  methods: {
    async query() {
      this.display = true;
      const url = `/AlarmReport/${this.form.alarmLevel}/${this.form.range[0]}/${this.form.range[1]}`;
      const res = await axios.get(url);
      const ret = res.data;
      for (const alarm of ret) {
        alarm.time = moment(alarm.time).format('lll');
        const src = alarm.src.split(':');
        alarm.src = src[1];
      }
      this.rows = ret;
    },
  },
});
